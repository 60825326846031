import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  toggleConfirmationModal(eventCheckbox) {
    if (!eventCheckbox.target.checked) {
      eventCheckbox.preventDefault();
      $('#preferenceToggleConfirmationModal .modal-body').text(eventCheckbox.params.confirmationMessage);

      $('#submitBtn').on('click', (e) => {
        eventCheckbox.target.checked = false;
        this.formTarget.requestSubmit();
      });

      $('#preferenceToggleConfirmationModal').on('hidden.bs.modal', (e) => {
        $('#submitBtn').off('click');
      });

      $('#preferenceToggleConfirmationModal').modal('show');
    } else {
      this.formTarget.requestSubmit();
    }
  }
}
