import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["previewContainer", "note", "submit", "hiddenTextarea", "timelineForm", "timelineIdsCreated", "fileUploadContainer"];

  filesToUpload = [];

  connect() {
    this.fileArray = [];
  }

  timelineFormTargetConnected() {
    this.previewContainerTarget.innerHTML = "";
  }

  submitStart() {
    const selectedFiles = this.fileUploadContainerTarget.querySelectorAll('.file-upload-input');
    const fileUploads = []
    selectedFiles.forEach((file) => {
      fileUploads.push(file.files[0])
    })
    this.filesToUpload = Array.from(fileUploads);
    this.submitTarget.disabled = true;
    this.submitTarget
      .querySelector(".spinner-border")
      ?.classList.remove("d-none");
    this.submitTarget.querySelector("span")?.classList.add("d-none");
    this.noteTarget.disabled = true;
  }

  timelineIdsCreatedTargetConnected(e) {
    const senderReferralTimelineId = e.dataset.senderReferralTimelineId;
    const receiverReferralTimelineId = e.dataset.receiverReferralTimelineId;

    if (senderReferralTimelineId && receiverReferralTimelineId && this.filesToUpload.length > 0) {
      this.attachFiles(senderReferralTimelineId, receiverReferralTimelineId, this.filesToUpload);
    }
  }

  attachFiles(senderReferralTimelineId, receiverReferralTimelineId, files) {
    const fieldUploadForm = document.querySelector('form#timeline-file-upload-form');
    const fileInput = fieldUploadForm.querySelector('input[name="file_upload[files][]"');
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));
    fileInput.files = dataTransfer.files;

    fieldUploadForm.action = "/file_uploads/upload_timeline_files";
    fieldUploadForm.querySelector('input[name="sender_referral_timeline_id"]').value = senderReferralTimelineId;
    fieldUploadForm.querySelector('input[name="receiver_referral_timeline_id"]').value = receiverReferralTimelineId;

    fieldUploadForm.requestSubmit();
  }

  displayNote() {
    const inputLines = this.noteTarget.value.split('\n');
    const firstLine = inputLines[0];
    const textWidth = this.noteTarget.clientWidth - this.hiddenTextareaTarget.clientWidth;
    let newRows = inputLines.length;

    this.hiddenTextareaTarget.value = firstLine;

    if (newRows > 1 || this.hiddenTextareaTarget.scrollWidth > this.noteTarget.clientWidth - textWidth) {
      newRows = this.noteTarget.dataset.limitRows;
    } else {
      newRows = this.noteTarget.dataset.initRows;
    }

    this.noteTarget.rows = newRows;
  }

  handleToggleRequireNote(e) {
    const selectedFiles = this.fileUploadContainerTarget.querySelectorAll('.file-upload-input');
    this.noteTarget.required = (selectedFiles.length === 0);
  }
}
