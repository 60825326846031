import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  handleGetModalCreate(event) {
    this.handleGetModal(event, `/admin/providers/modal_create`, '#modal_create_provider')
  }

  Show(event) {
    this.handleGetModal(event, `/admin/providers/${event.params.id}/modal_show`, '#modal_show_provider')
  }

  Edit(event) {
    this.handleGetModal(event, `/admin/providers/${event.params.id}/modal_edit`, '#modal_edit_provider', '#modal_show_provider')
  }

  handleBackModal(event) {
    this.handleGetModal(event, `/admin/providers/${event.params.id}/modal_show`, '#modal_show_provider', '#modal_edit_provider')
  }

  handleGetModal(event, url, modal, modalRemove = null) {
    event.preventDefault();
    $.ajax({
      url,
      type: 'GET',
      success: function(data) {
        $('.container-fluid.dashboard-container').append(data);
        if (modalRemove) {
          $(modalRemove).modal('hide').on('hidden.bs.modal', function() {
            $(this).remove();
          });
        }
        $(modal).modal('show').on('hidden.bs.modal', function() {
          $(this).remove();
        });
      }
    });
  }
}