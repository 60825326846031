import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "patientForm",
    "searchPatientForm",
    "addNewPatientButton",
    "patientDzItemsWrapper",
    "patientDzItems",
    "patientInput",
  ]

  chooseExistingPatient(event) {
    this.patientFormTarget.classList.add('d-none');
    this.searchPatientFormTarget.classList.remove('d-none');
    this.addNewPatientButtonTarget.classList.remove('d-none');
  }

  patientInputTargetConnected(e) {
    e.addEventListener("focusout", (e) => {
      setTimeout(() => {
        this.patientDzItemsWrapperTarget.classList.add("d-none");
      }, 200)
    });
  }

  searchPatients(e){
    setTimeout(() => {
      const searchText = e.target.value;

      if (!searchText.length) {
        this.patientDzItemsWrapperTarget.classList.add('d-none');
        return;
      }

      $.ajax({
        url: `/referrals/patients_by_organization`,
        type: 'GET',
        dataType: 'HTML',
        data: { search_text: searchText },
        success: (data) => {
          this.patientDzItemsTarget.innerHTML = data;
        }
      });

      this.patientDzItemsWrapperTarget.classList.remove('d-none');
    }, 300);
  }

  addNewPatient(event) {
    this.patientFormTarget.classList.remove('d-none');
    this.searchPatientFormTarget.classList.add('d-none');
    this.addNewPatientButtonTarget.classList.add('d-none');
  }
}
