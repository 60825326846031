import { Controller } from "@hotwired/stimulus"
import { addInputEventForRequiredFields, checkNewReferralFormSubmitButton } from "../helpers";

export default class extends Controller {
  static targets = ["submitButton", "imageContainer", "withImages", "withoutImages"]

  connect() {
    addInputEventForRequiredFields();
    // TODO: Testing
    // checkNewReferralFormSubmitButton();
  }

  handleSubmit(e) {
    if (this.imageContainerTarget.querySelectorAll('.file-wrapper').length === 0) {
      this.withImagesTarget.style.display = 'none';
      this.withoutImagesTarget.classList.remove('d-none');
    } else {
      e.target.closest('form').requestSubmit();
    }
    this.submitButtonTarget.disabled = true;
  }

  handleFileChange(e) {
    this.withImagesTarget.style.display = 'block';
    this.withoutImagesTarget.classList.add('d-none');
  }

  handleNo(e) {
    this.withImagesTarget.style.display = 'block';
    this.withoutImagesTarget.classList.add('d-none');
  }
}
