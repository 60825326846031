// app/javascript/helpers/index.js

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function checkAvailableForSubmitBtn($el) {
  let filled = true;
  const $form = $el.closest('form')

  // check input[type=text] in form
  $form.find("input[type=text]:not(.not-require)").each((index, element) => {
    if($(element).val() == ""){
      filled = false;
    };
  });
  // check select2 in form
  $form.find(".select2-selection__rendered").each((index, element) => {
    if ($(element).text().length == 0) {
      filled = false;
    }
  });

  const $submitBtn = $form.find(".btn-submit")

  if(filled === false){
    $submitBtn.removeClass('bg-light-primary')
    $submitBtn.addClass('bg-light-silver')
    $submitBtn.prop( "disabled", true );
  } else {
    $submitBtn.addClass('bg-light-primary')
    $submitBtn.removeClass('bg-light-silver')
    $submitBtn.prop( "disabled", false );
  }
}

export function checkFiles(files) {
  let selectedFiles = []

  Array.from(files).forEach((file, _index) => {
    const maximumFileSizeInMb = parseInt($('#server-data').data('maximum-file-size-in-mb'));
    if(file.size > 1024*1024*maximumFileSizeInMb){
      alert(`File ${file?.name} not added, you can only upload a maximum of ${maximumFileSizeInMb}mb for each file. Please upgrade your plan to upload larger files.`);
      return
    }

    selectedFiles.push(file)
  })

  const dataTransfer = new DataTransfer()
  selectedFiles.forEach((file) => dataTransfer.items.add(file))

  return [selectedFiles, dataTransfer.files]
}

export function loadingSpinner(value) {
  if (value) {
    $('.loading-container').removeClass('d-none')
  } else {
    $('.loading-container').addClass('d-none')
  }
}

export function attachFile(fileUploadableId, fileUploadableType, fileUploadId, file) {
  const fileUploadForm = document.createElement('form');
  fileUploadForm.setAttribute('action', `/file_uploads/${fileUploadId}/attach`);
  fileUploadForm.setAttribute('class', 'd-none');
  fileUploadForm.setAttribute('method', 'post');
  fileUploadForm.setAttribute('enctype', 'multipart/form-data');
  fileUploadForm.setAttribute('data-remote', 'true');

  const authTokenInput = document.createElement('input');
  authTokenInput.setAttribute('type', 'hidden');
  authTokenInput.setAttribute('name', 'authenticity_token');
  authTokenInput.setAttribute('value', document.querySelector('meta[name="csrf-token"]').content);
  fileUploadForm.appendChild(authTokenInput);

  const methodInput = document.createElement('input');
  methodInput.setAttribute('type', 'hidden');
  methodInput.setAttribute('name', '_method');
  methodInput.setAttribute('value', 'patch');
  fileUploadForm.appendChild(methodInput);

  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(file);
  const fileInput = document.createElement('input');
  fileInput.setAttribute('type', 'file');
  fileInput.setAttribute('name', 'file_upload[file]');
  fileInput.files = dataTransfer.files;
  fileUploadForm.appendChild(fileInput);

  const fileUploadIdInput = document.createElement('input');
  fileUploadIdInput.setAttribute('type', 'hidden');
  fileUploadIdInput.setAttribute('name', 'id');
  fileUploadIdInput.setAttribute('value', fileUploadId);
  fileUploadForm.appendChild(fileUploadIdInput);

  const fileUploadableIdInput = document.createElement('input');
  fileUploadIdInput.setAttribute('type', 'hidden');
  fileUploadableIdInput.setAttribute('name', 'file_uploadable_id');
  fileUploadableIdInput.setAttribute('value', fileUploadableId);
  fileUploadForm.appendChild(fileUploadableIdInput);

  const fileUploadableTypeInput = document.createElement('input');
  fileUploadIdInput.setAttribute('type', 'hidden');
  fileUploadableTypeInput.setAttribute('name', 'file_uploadable_type');
  fileUploadableTypeInput.setAttribute('value', fileUploadableType);
  fileUploadForm.appendChild(fileUploadableTypeInput);

  document.body.appendChild(fileUploadForm);

  fileUploadForm.requestSubmit();
  fileUploadForm.remove();
}

export function checkNewReferralFormSubmitButton() {
  setTimeout(() => {
    const requiredFields = document.querySelector('#new_referral_form').querySelectorAll(
      "input[type=text][required]:not([disabled]), input[type=hidden][required]:not([disabled]), textarea[required]:not([disabled])"
    );

    const isAnyUnfilledFields = [...requiredFields].some(
      (field) => !field.value.trim()
    );

    document.querySelector('#newReferralFormSubmitButton').classList.toggle("btn-lock", isAnyUnfilledFields);
  }, 200);
}

export function addInputEventForRequiredFields() {
  const requiredFields = document.querySelector('#new_referral_form').querySelectorAll(
    "input[type=text][required]:not([disabled]), input[type=hidden][required]:not([disabled]), textarea[required]:not([disabled])"
  );

  requiredFields.forEach((field) => {
    field.addEventListener("input", checkNewReferralFormSubmitButton);
  });
}
