import { Controller } from "@hotwired/stimulus";
import { checkFiles, loadingSpinner } from "../helpers"

export default class extends Controller {
  static targets = ["rvsForm", "locationConfirmation", "moreDetailsButton", "hideDetailsButton"]

  connect() {
    this.registerAddToCollectionEvent('.endodontics, .implants')
    this.registerRemoveFromCollectionEvent('.endodontics, .implants')
  }

  moreDetails(e) {
    this.locationConfirmationTarget.classList.remove('d-none');
    this.moreDetailsButtonTarget.classList.add('d-none');
    this.hideDetailsButtonTarget.classList.remove('d-none');
  }

  hideDetails(e) {
    this.locationConfirmationTarget.classList.add('d-none');
    this.moreDetailsButtonTarget.classList.remove('d-none');
    this.hideDetailsButtonTarget.classList.add('d-none');
  }

  uploadImages(e){
    const files = e.target.files;
    const [selectedFiles, dataTransferFiles] = checkFiles(files);
    const selectingFilesAmount = files.length;
    const existingFileAmount = $('#rvs-photograph-container .file-wrapper input').length;

    if (existingFileAmount + selectingFilesAmount > 5) {
      alert("You can only upload a maximum of 5 files")
      e.target.value = '';
      return;
    }

    selectedFiles.forEach((file, index) => {
      const fileWrapperEl = $('<div class="file-wrapper d-flex align-items-center" style="background-color: #E4E4E4; border: 1px solid #E4E4E4; border-radius: 8px; height: 70px; margin-top: 8px;"></div>')
      $('#rvs-photograph-container').append(fileWrapperEl.
        append($('<div class="flex-grow-1 ps-2 d-flex"></div>')
                .append($('<div><i class="icon-image-upload"></i></div>'))
                .append($('<div class="file-name font-14" style="margin-left: 10px;"></div>').text(file.name))
              ).append($('<div class="close-wrapper pe-2 d-flex"><i class="icon-close-image p-2 cursor-pointer" data-action="click->referral-visit-summary#removeImage" ></i></div>'))
      );

      const hiddenField = document.createElement('input');
      hiddenField.setAttribute("type", "file");
      hiddenField.setAttribute("class", "d-none");
      hiddenField.name = e.target.name;
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(dataTransferFiles[index]);
      hiddenField.files = dataTransfer.files;
      fileWrapperEl.append(hiddenField);
    })
    // We will use these hidden input fields above to submit the files to the server instead of
    // the original input field, so we need to clear the original input field.
    e.target.value = '';
  }

  removeImage(e){
    e.currentTarget.closest('.file-wrapper').remove()
  }

  checkImages() {
    const imagesCount = $('#rvs-photograph-container').children().length;
    if (imagesCount > 0) {
      this.toggleSubmitConfirm(false);
      this.submitRvs();
    } else {
      this.toggleSubmitConfirm(true);
    }
  }

  submitRvs() {
    this.rvsFormTarget.requestSubmit();
    loadingSpinner(true);
  }

  holdOnBtn() {
    this.toggleSubmitConfirm(false);
  }

  toggleSubmitConfirm(value) {
    if (value) {
      $('.submit-with-images').addClass('d-none');
      $('.submit-without-image').removeClass('d-none');
    } else {
      $('.submit-with-images').removeClass('d-none');
      $('.submit-without-image').addClass('d-none');
    }
  }

  handleCollapse(e) {
    const collapseContent = e.currentTarget.closest('.collapse-wrapper').querySelector('.collapse-content');
    collapseContent.classList.toggle('show');

    if (collapseContent.classList.contains('show')) {
      e.currentTarget.querySelector('.fas').classList.add('fa-chevron-up');
      e.currentTarget.querySelector('.fas').classList.remove('fa-chevron-right');
      e.currentTarget.classList.remove('border-bottom');
    } else {
      e.currentTarget.querySelector('.fas').classList.add('fa-chevron-right');
      e.currentTarget.querySelector('.fas').classList.remove('fa-chevron-up');
      e.currentTarget.classList.add('border-bottom');
    }
  }

  registerAddToCollectionEvent(collectionClass) {
    $(collectionClass).on('click', '[data-action="add"]', function(event) {
      const parent = $(this).closest(collectionClass);
      const fields = $(this).data('fields');
      const id = (parent.data('id') || 0) + 1
      const nestedFieldsLast = parent.find('.nested-fields:last');
      const updatedFields = fields.replace(/\[\d\]/g, `[${id}]`);

      if (nestedFieldsLast.length) {
        nestedFieldsLast.after(updatedFields)
      } else {
        parent.find('.nested-field-wrapper').prepend(updatedFields)
      }

      parent.data('id', id)
      event.preventDefault();
    });
  }

  registerRemoveFromCollectionEvent(collectionClass) {
    $(collectionClass).on('click', '[data-action="remove"]', function(event) {
      $(this).closest('.nested-fields').remove()
      event.preventDefault();
    });
  }
}
