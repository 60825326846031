import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  handleGetModalCreate(event) {
    this.handleGetModal(event, "/admin/users/modal_create", "#modal_create_user")
  }

  Show(event) {
    this.handleGetModal(event, `/admin/users/${event.params.id}/modal_show`, "#modal_show_user")
  }

  Edit(event) {
    this.handleGetModal(event, `/admin/users/${event.params.id}/modal_edit`, "#modal_edit_user")
  }

  handleBackModal(event) {
    this.handleGetModal(event, `/admin/users/${event.params.id}/modal_show`, '#modal_show_user', '#modal_edit_user')
  }

  handleGetModal(event, url, modal) {
    event.preventDefault();
    $.get(url, (data) => {
      $(".container-fluid.dashboard-container").append(data)
      $(modal).modal("show").on("hidden.bs.modal", () => $(modal).remove())
    })
  }
}