import { Controller } from "@hotwired/stimulus";
import { checkFiles, attachFile } from "../helpers"
import { Tooltip } from "bootstrap"

export default class extends Controller {
  static targets = ["referralPhotographContainer", "bgUploadFile"];

  toggleInitialUploadFileBlock() {
    if (!this.hasBgUploadFileTarget || !this.referralPhotographContainerTarget) return;

    if (this.referralPhotographContainerTarget.querySelectorAll('.file-wrapper').length === 0) {
      this.bgUploadFileTarget.classList.remove('d-none');
    } else {
      this.bgUploadFileTarget.classList.add('d-none');
    }
  }

  uploadFiles(e){
    const files = e.target.files;
    const [selectedFiles] = checkFiles(files);
    const selectingFilesAmount = files.length;
    const existingFileAmount = this.referralPhotographContainerTarget.querySelectorAll('.file-wrapper').length;

    if (existingFileAmount + selectingFilesAmount > 5) {
      alert("You can only upload a maximum of 5 files")
      e.target.value = '';
      return;
    }

    selectedFiles.forEach((file, index) => {
      const countItems = document.querySelectorAll('.file-wrapper').length;
      const fileWrapperEl = $('<div class="file-wrapper d-flex align-items-center" style="border: 1px solid #E4E4E4; border-radius: 8px; height: 70px; margin-bottom: 8px;"></div>')
      const fileNameSuffix = file.name.split('.').pop();
      const fileBaseName = file.name.split('.').slice(0, -1).join('.');
      const $fileNameEditable = $(`<span class="file-name-editable" contentEditable="true"  data-action="input->upload-referral-photograph#renameFile" data-file-suffix="${fileNameSuffix}" style="outline: none;">${fileBaseName}</span>`)
      const $fileName = $(`<div class="file-name font-14 w-100" style="min-width: 0;"></div>`);
      $fileName.append($fileNameEditable).append(`.${fileNameSuffix}`);

      $(this.referralPhotographContainerTarget).append(
        fileWrapperEl.append(
          $('<div class="flex-grow-1 ps-2 d-flex align-items-center h-100" style="min-width: 0;"></div>')
            .append($('<div><i class="icon-image-upload"></i></div>'))
            .append(
              $('<div class="d-flex flex-column justify-content-center flex-grow-1" style="margin-left: 10px; min-width: 0;"></div>')
                .append($('<div class="d-flex align-items-center" style="font-size: 10px; color: #00000080">File name</div>'))
                .append($fileName)
            )
        ).append(
          $(`<div class="close-wrapper pe-2 d-flex">
              <i class="icon-close-image p-2 cursor-pointer" data-action="click->upload-referral-photograph#resetFileName" data-origin-name="${file.name}"></i>
              <i class="icon-trash-light p-2 cursor-pointer" data-action="click->upload-referral-photograph#removeFileUpload"></i>
              </div>`)
        )
      );

      const hiddenField = document.createElement('input');
      hiddenField.setAttribute("type", "file");
      hiddenField.setAttribute("class", "d-none");
      hiddenField.name = `file_upload[${countItems}][file]`;
      hiddenField.classList.add('file-upload-input');

      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      hiddenField.files = dataTransfer.files;
      fileWrapperEl.append(hiddenField);

      const limitFileSizeInMbToRunCronJob = parseInt($('#server-data').data('limit-file-size-in-mb-to-run-cronjob'));
      if(file.size > 1024*1024*limitFileSizeInMbToRunCronJob){
        hiddenField.disabled = true;
        hiddenField.classList.add('large-file-upload');
        const tooltipLargeFileEl = $('<i class="fa-solid fa-circle-exclamation fa-lg p-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Large file size"></i>');
        $fileName.parent().after(
          tooltipLargeFileEl
        );
        new Tooltip(tooltipLargeFileEl);
      }
    })
    // We will use these hidden input fields above to submit the files to the server instead of
    // the original input field, so we need to clear the original input field.
    e.target.value = '';

    this.toggleInitialUploadFileBlock();
  }

  renameFile(e){
    const fileInput = e.currentTarget.closest('.file-wrapper').querySelector("input[type='file']");
    const fileName = e.currentTarget.innerText;
    const fileNameSuffix = e.currentTarget.dataset.fileSuffix;
    const newFileName = `${fileName}.${fileNameSuffix}`;

    const file = fileInput.files[0];
    const newFile = new File([file], newFileName, { type: file.type })
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(newFile);
    fileInput.files = dataTransfer.files;
  }

  resetFileName(e) {
    const originalFileName = e.currentTarget.dataset.originName;
    const fileInput = e.currentTarget.closest('.file-wrapper').querySelector("input[type='file']");
    const fileNameEl = e.currentTarget.closest('.file-wrapper').querySelector('.file-name span:first-child');
    fileNameEl.innerText = originalFileName.split('.').slice(0, -1).join('.');

    const file = fileInput.files[0];
    const newFile = new File([file], originalFileName, { type: file.type })
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(newFile);
    fileInput.files = dataTransfer.files;
  }

  createFileUpload(fileUploadableId, fileUploadableType, file) {
    $.ajax({
      url: '/file_uploads',
      type: "POST",
      dataType: "json",
      data: {
        file_uploadable_id: fileUploadableId,
        file_uploadable_type: fileUploadableType,
        file_upload: {
          name: file.name
        }
      },
      success: (fileUpload) => {
        attachFile(fileUploadableId, fileUploadableType, fileUpload.id, file);
      },
      error: (error) => {
        console.error("Error uploading file", error)
      },
    });
  }

  removeFileUpload(event) {
    if (event.target.dataset.fileUploadId) {
      const fileUploadDestroyInput = $(`<input type="text" name="file_upload[${event.target.dataset.fileUploadId}][_destroy]" value="1" class="d-none" />`);
      const fileUploadIdInput = $(`<input type="text" name="file_upload[${event.target.dataset.fileUploadId}][id]" value="${event.target.dataset.fileUploadId}" class="d-none" />`);
      $(this.referralPhotographContainerTarget).append(fileUploadDestroyInput).append(fileUploadIdInput);
    }

    $(event.target).closest('.file-wrapper').remove();

    this.toggleInitialUploadFileBlock();
  }

  attachLargeFileUpload(event) {
    const detail = event.detail;
    if (!detail || !detail.success) return;

    const largeFileUploads = [];
    this.referralPhotographContainerTarget.querySelectorAll('input.large-file-upload').forEach(fileInput => {
      largeFileUploads.push(fileInput.files[0]);
    });
    if (largeFileUploads.length === 0) return;

    event.detail.fetchResponse.responseHTML.then(text => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');
      const referralIdEl = doc.querySelector('#referral-id');

      if (referralIdEl) {
        const referralDetailsId = referralIdEl.dataset.referralId;
        largeFileUploads.forEach(file => {
          this.createFileUpload(referralDetailsId, 'Referral', file);
        });
      }
    })
  }
}
