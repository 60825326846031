import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "submitButton",
    "recipientProvider",
    "recipientProviderBlock",
    "formField",
    "checkbox",
    "imageContainer",
    "withImages",
    "withoutImages"
  ];

  connect() {
    this.updateSubmitButtonState();
    this.formFieldTargets.forEach(target => {
      target.addEventListener('input', () => this.updateSubmitButtonState());
    });
    this.checkboxTargets.forEach(target => {
      target.addEventListener('change', () => this.updateSubmitButtonState());
    });
    this.checkFileUploads();
  }

  showRecipientProvider() {
    this.recipientProviderTarget.value = ''
    this.recipientProviderBlockTarget.classList.remove('d-none')
  }

  hideRecipientProvider() {
    this.recipientProviderTarget.value = ''
    this.recipientProviderBlockTarget.classList.add('d-none')
  }

  updateSubmitButtonState() {
    const areAllFieldsFilled = this.formFieldTargets.every(field => field.value.trim() !== '');
    const areAllCheckboxesChecked = this.checkboxTargets.every(checkbox => checkbox.checked);

    const isAnyProviderSelected = document.getElementById('referral_any_provider').checked;

    if (isAnyProviderSelected) {
      if (areAllCheckboxesChecked) {
        this.submitButtonTarget.classList.remove('btn-lock');
      } else {
        this.submitButtonTarget.classList.add('btn-lock');
      }
    } else {
      if (areAllFieldsFilled && areAllCheckboxesChecked) {
        this.submitButtonTarget.classList.remove('btn-lock');
      } else {
        this.submitButtonTarget.classList.add('btn-lock');
      }
    }
  }

  checkFileUploads() {
    const fileInputs = this.imageContainerTarget.querySelectorAll('.file-wrapper input[type="file"]');
    const hasFiles = Array.from(fileInputs).some(input => input.files.length > 0);

    if (hasFiles) {
      this.withImagesTarget.style.display = 'block';
      this.withoutImagesTarget.classList.add('d-none');
    } else {
      this.withImagesTarget.style.display = 'block';
      this.withoutImagesTarget.classList.add('d-none');
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const fileInputs = this.imageContainerTarget.querySelectorAll('.file-wrapper input[type="file"]');
    const hasFiles = Array.from(fileInputs).some(input => input.files.length > 0);

    if (!hasFiles) {
      this.withImagesTarget.style.display = 'none';
      this.withoutImagesTarget.classList.remove('d-none');
    } else {
      e.target.closest('form').requestSubmit();
    }
  }

  handleFileChange(e) {
    this.checkFileUploads();
  }

  handleNo(e) {
    this.withImagesTarget.style.display = 'block';
    this.withoutImagesTarget.classList.add('d-none');
  }

  handleYes(e) {
    this.submitButtonTarget.closest('form').requestSubmit();
  }
}
